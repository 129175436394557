import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Header } from 'components/theme';
import { Container, Button } from 'components/common';
import dev from 'assets/illustrations/dev.svg';
import Typewriter from 'typewriter-effect';
import { Wrapper, IntroWrapper, Details, Thumbnail } from './styles';

export const Intro = () => (
  <Wrapper>
    <Header />
    <IntroWrapper as={Container}>
      <Details>
        <h1>Bem-vindo!</h1>
        <h4 id="typewriter">
          Eu sou Elienay,
          <Typewriter
            options={{ loop: true }}
            onInit={typewriter => {
              typewriter
                .changeDelay(50)
                .typeString('um desenvolvedor full-stack.')
                .deleteChars('full-stack.'.length)
                .typeString('back-end.')
                .changeDeleteSpeed(15)
                .changeDelay(40)
                .deleteAll()
                .typeString('um engenheiro de software.')
                .pauseFor(3600)
                .start();
            }}
          />
        </h4>
        <Button style={{ backgroundColor: '#F50057' }} as={AnchorLink} href="#contact">
          Fale comigo
        </Button>
      </Details>
      <Thumbnail>
        <img src={dev} alt="Eu sou Elienay, um engenheiro full-stack" />
      </Thumbnail>
    </IntroWrapper>
  </Wrapper>
);
